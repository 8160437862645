import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Vi dyker ner i lite historia och statistik kring Fediverse. Ubuntu satsar på Wayland, Firefox ger upp på SSB och Spotify vill lyssna på dig.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Fediverse rapporterar: `}<a parentName="li" {...{
          "href": "https://fediverse.party/en/post/fediverse-in-2020"
        }}>{`https://fediverse.party/en/post/fediverse-in-2020`}</a></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`Ubuntu 21.04 kör wayland: `}<a parentName="li" {...{
          "href": "https://www.omgubuntu.co.uk/2021/01/ubuntu-21-04-will-use-wayland-by-default"
        }}>{`https://www.omgubuntu.co.uk/2021/01/ubuntu-21-04-will-use-wayland-by-default`}</a></li>
      <li parentName="ul">{`FOSDEM Online (tack bittin): `}<a parentName="li" {...{
          "href": "https://fosdem.org/2021/"
        }}>{`https://fosdem.org/2021/`}</a></li>
      <li parentName="ul">{`Firefox 85: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/85.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/85.0/releasenotes/`}</a></li>
      <li parentName="ul">{`Google Play portar (matrix) Element: `}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://news.ycombinator.com/item?id=25964226"
            }}>{`https://news.ycombinator.com/item?id=25964226`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://twitter.com/element_hq/status/1355290296947499013"
            }}>{`https://twitter.com/element_hq/status/1355290296947499013`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Firefox ger upp på SSB: `}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://bugzilla.mozilla.org/show_bug.cgi?id=1682593"
            }}>{`https://bugzilla.mozilla.org/show_bug.cgi?id=1682593`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://old.reddit.com/r/linux/comments/l6i8xv/firefox_giving_up_on_pwa_support/"
            }}>{`https://old.reddit.com/r/linux/comments/l6i8xv/firefox_giving_up_on_pwa_support/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Spotify vill lyssna: `}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://pitchfork.com/news/new-spotify-patent-involves-monitoring-users-speech-to-recommend-music/"
            }}>{`https://pitchfork.com/news/new-spotify-patent-involves-monitoring-users-speech-to-recommend-music/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://twitter.com/dhh/status/1355036612569296897?s=09"
            }}>{`https://twitter.com/dhh/status/1355036612569296897?s=09`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Pop!`}{`_`}{`OS del 2`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Folk hittar till matrix, kul!`}</li>
      <li parentName="ul">{`Lämna en trevlig recension :)`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" by Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      